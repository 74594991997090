import { queueMessage } from "~shared/snackbarsProvider/actions";

import { createLogRemoteError } from "~libs/createLogRemoteError";

let latestAction = null;

const errorsMiddleware = store => next => action => {
  if (action.error) {
    const errorMessage =
      action.payload && action.payload.message
        ? action.payload.message
        : "generic-exception";

    console.debug("Error track", errorMessage, action.type, latestAction);

    const logRemoteError = createLogRemoteError();

    if (errorMessage === "Failed to fetch")
      store.dispatch(
        queueMessage(
          "Ocurrió un error. Por favor, verificá que tengas conexión a internet y volvé a intentar en unos instantes."
        )
      );
    else
      store.dispatch(
        queueMessage(
          "Ocurrió un error. Por favor, volvé a intentar en unos instantes."
        )
      );

    logRemoteError(errorMessage, action.type, latestAction, store.getState());
  } else {
    latestAction = action;
  }

  return next(action);
};

export default errorsMiddleware;
