import winston from "winston";
import CloudWatchTransport from "winston-aws-cloudwatch";
import environment from "./environment";
import uuidv4 from "uuid/v4";

export function createLogRemoteError() {
  try {
    if (environment.name === "localhost")
      return (...params) => console.error("remote error", params);

    const logStreamName = `${environment.name}-${uuidv4()}`;

    const loggerInstance = winston.createLogger({
      transports: [
        new CloudWatchTransport({
          logGroupName: "seguros-react",
          logStreamName: logStreamName,
          createLogGroup: false,
          createLogStream: true,
          submissionInterval: 2000,
          submissionRetryCount: 1,
          batchSize: 20,
          awsConfig: {
            accessKeyId: environment.awsLogs.apiKey,
            secretAccessKey: environment.awsLogs.apiSecret,
            region: "us-east-1"
          },
          formatLog: item =>
            `${item.level}: ${item.message} ${JSON.stringify(item.meta)}`
        })
      ]
    });

    return (error, actionType, latestAction, currentState) => {
      try {
        const exception =
          error instanceof Error ? error : JSON.stringify(error);

        loggerInstance.error(exception, {
          actionType: actionType,
          latestAction: latestAction,
          state: currentState
        });
      } catch (error) {
        console.error("remote log not working!!!!!!!!!!!!!!!", error);
      }
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}
