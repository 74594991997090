import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import PolizaSelector from "./polizaSelector/PolizaSelector";
import SlideCustom from "~shared/slideCustom/SlideCustom";
import { backToEligeTuPoliza } from "../polizaDetailStep/actions";

const useStyles = makeStyles(theme => ({
  cotizadorPoliza: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    paddingBottom: 40,
    maxWidth: 500
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    showDetail: state.polizaDetailSegurosReducer.showDetail
  }));

const PolizasStep = memo(({ backAction, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showDetail } = useReduxSelector();

  useEffect(() => {
    dispatch(backToEligeTuPoliza());
  }, [dispatch]);

  return (
    <>
      <TopBar title={"Elegí una Póliza"} />
      <SlideCustom backNav={showDetail}>
        <div className={classes.cotizadorPoliza}>
          <PolizaSelector />
        </div>
      </SlideCustom>
    </>
  );
});

export default PolizasStep;
