import React, { useState, Fragment, useEffect, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "../listView/listView";
import Searcher from "../searcher/searcher";
import filterItems from "~libs/filterItems";
import { useSelector, useDispatch } from "react-redux";
import { fetchCodigosPostales } from "./actions";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isLoading: state.codigosPostalesReducer.isLoading,
    codigosPostales: state.codigosPostalesReducer.codigosPostales
  }));

const CodigoPostalSelector = memo(({ value, provincia, onChange }) => {
  const [filter, setFilter] = useState("");

  const { codigosPostales, isLoading } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (provincia.value) dispatch(fetchCodigosPostales(provincia.value));
  }, [dispatch, provincia.value]);

  const handleItem = value => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();
  return (
    <Fragment>
      {!value.label && (
        <div className={classes.searcher}>
          <Searcher
            onClear={() => handleItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar código postal"}
          />
          <ListView
            isLoading={isLoading}
            selectItem={handleItem}
            list={
              filter === ""
                ? codigosPostales
                : filterItems(codigosPostales, filter)
            }
          />
        </div>
      )}
    </Fragment>
  );
});

export default CodigoPostalSelector;
