import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const green1 = "#4aba7c";
const green2 = "#4aba7c";
const green3 = "#36885B";
const green4 = "#fff";

const blue1 = "#45459c";
const blue2 = "#45459c";

const theme = createMuiTheme({
  colours: {
    white: "#FFFFFF",
    black: "#000000",
    verdeMgBroker: "#4aba7c",
    violeta: "#45459c",
    grey2: "#bfbfbf",
    grey5: "#5b5d60",
    brownGrey: "#8e8e8e",
    brownGrey2: "#a7a7a7",
    lightIndigo: "#6868bf"
  },
  palette: {
    primary: {
      light: green1,
      main: green2,
      dark: green3,
      contrastText: green4
    },
    secondary: {
      light: blue1,
      main: blue1,
      dark: blue1,
      contrastText: blue1
    }
  },
  typography: {
    fontFamily: '"Nunito", Helvetica, Arial, sans-serif'
  },
  textField: {
    borderColor: blue1,
    floatingLabelShrinkColor: blue2
  }
});

export default theme;
