import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import EmailField from "./EmailField";
import InputField from "./InputField";
import TelefonoField from "./TelefonoField";
import Typography from "@material-ui/core/Typography";
import {
  selectNombre,
  putEnvioDatosPersona,
  selectConfirm,
  selectEmail,
  selectTelefono
} from "./actions";
import TopBar from "~shared/TopBar";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "10vh"
  },
  input: { paddingBottom: 10 },
  button: {
    width: 170,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    }
  },
  title: {
    fontSize: 28,
    fontWeight: 800,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.11,
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.colours.violeta
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#434343",
    paddingBottom: 40
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    nombreSelected: state.agregarDatosPersonaReducer.nombreSelected,
    emailSelected: state.agregarDatosPersonaReducer.emailSelected,
    telefonoSelected: state.agregarDatosPersonaReducer.telefonoSelected,
    cotizacionId: state.cotizadorSeguroReducer.cotizacionId,
    currentPoliza: state.polizaDetailSegurosReducer.currentPoliza
  }));

const IngresaTusDatosStep = () => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const {
    nombreSelected,
    emailSelected,
    telefonoSelected,
    cotizacionId,
    currentPoliza
  } = useReduxSelector();

  const handleChangeNombre = event => {
    setSubmitting(false);
    dispatch(selectNombre(event.target.value));
  };

  const handleChangeEmail = event => {
    setSubmitting(false);
    dispatch(selectEmail(event.target.value));
  };

  const handleChangeTelefono = event => {
    setSubmitting(false);
    dispatch(selectTelefono(event.target.value));
  };

  const handleSend = () => {
    setSubmitting(true);

    const allComplete = nombreSelected.valid && emailSelected.valid;

    if (allComplete) {
      dispatch(
        putEnvioDatosPersona(
          nombreSelected.value,
          emailSelected.value,
          telefonoSelected.value,
          cotizacionId,
          currentPoliza.cotizacionPlanId
        )
      );

      dispatch(selectConfirm(true));
      //dispatch(getPolizasSeguros(cotizacionId));
    }
  };

  const classes = useStyles();

  return (
    <>
      <TopBar title={"Ingresá tus datos"} />
      <SlideCustom backNav={false}>
        <div className={classes.cotizador}>
          <div style={{ minHeight: "10vh" }}>
            <Typography color="textPrimary" className={classes.title}>
              {"¡Último paso!"}
            </Typography>
            <Typography color="textPrimary" className={classes.subtitle}>
              {"Dejanos un dato de contacto para"}
              <br />
              {" completar la contratación de tu seguro "}
              <br />
              {"de manera personalizada."}
            </Typography>
          </div>
          <div style={{ minHeight: "20vh" }}>
            <div className={classes.input}>
              <InputField
                label="Nombre"
                displayError={submitting}
                autoFocus={true}
                required={true}
                value={nombreSelected.value}
                onChange={handleChangeNombre}
              />
            </div>
            <div className={classes.input}>
              <EmailField
                displayError={submitting}
                required={true}
                value={emailSelected.value}
                onChange={handleChangeEmail}
              />
            </div>
            <div className={classes.input}>
              <TelefonoField
                displayError={submitting}
                optional={true}
                required={true}
                value={telefonoSelected.value}
                onChange={handleChangeTelefono}
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={handleSend}
              variant="contained"
              color="primary"
            >
              ENVIAR
            </Button>
          </div>
        </div>
      </SlideCustom>
    </>
  );
};

export default IngresaTusDatosStep;
