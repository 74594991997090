import React, { useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import people from "~images/home/People.svg";
import makeStyles from "@material-ui/styles/makeStyles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import waveBackground from "~images/home/wave-bg.svg";
import Footer from "~shared/footer/Footer";
import berkleyLogo from "~images/home/logo-berkley-white.svg";
import sancorLogo from "~images/home/logo-sancor-seguros-white.svg";
import zurichLogo from "~images/home/logo-zurich-white.svg";
import triunfoLogo from "~images/home/logo-triunfo.png";
import mercantilAndinaLogo from "~images/home/logo-mercantil_andina.svg";
import rioLogo from "~images/home/logo-rio.svg";
import getUrlParam from "~libs/urlParams";
import { nuevaCotizacionHomeButton, nuevaCotizacionIcon } from "./actions";
import { useDispatch } from "react-redux";
import {
  setCampaniaPublicitariaSource,
  setSocketId,
} from "../../redux/appReducer";
import Lottie from "lottie-react-web";
import iconPersonAnimation from "../../animations/icon-person.json";
import iconCarAnimation from "../../animations/icon-car.json";
import iconSeguroAnimation from "../../animations/icon-seguro.json";
import TopBarHome from "~shared/topBarHome/TopBarHome";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    overflow: "hidden",
    width: "100vw",
  },
  waveContainer: {
    display: "flex",
    marginTop: 40,
    width: "100vw",
    height: "100vh",
    maxHeight: 800,
    justifyContent: "center",
    backgroundImage: `url(${waveBackground})`,
    backgroundPosition: "-15vw top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "160% 120vh",
    "@media (min-height:1280px)": {
      height: "80%",
      backgroundSize: "140% 80vh",
    },
    "@media (min-width:1280px)": {
      height: "50vh",
      backgroundSize: "115% 80vh",
    },
  },
  //Header section
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
  },
  columnLeft: {
    zIndex: 1,
    display: "flex",
    textAlign: "left",
    maxWidth: "900px",
    flexDirection: "column",
    //padding: "7vh 4vw",
    "@media (min-width:1280px)": {
      width: "50%",
    },
  },
  titleHeader: {
    zIndex: 1,
    display: "flex",
    color: theme.colours.white,
    fontSize: "6vh",
    lineHeight: "5.7vh",
    fontWeight: "bold",
    maxWidth: "85vw",
    marginTop: 30,
    "@media (min-width:1024px)": {
      fontSize: "3rem",
      lineHeight: "1.2",
      maxWidth: "35rem",
    },
    "@media (min-width:1024px) and (orientation:portrait)": {
      fontSize: "4rem",
      maxWidth: "100%",
    },
  },
  subtitleHeader: {
    zIndex: 1,
    color: theme.colours.white,
    lineHeight: "5vh",
    fontSize: "3.5vh",
    maxWidth: "85vw",
    marginTop: 30,
    "@media (min-width:1024px)": {
      fontSize: "2rem",
      lineHeight: "1.2",
      maxWidth: "26rem",
    },
  },
  subtitleHeaderBold: {
    zIndex: 1,
    color: theme.colours.white,
    fontSize: "3.5vh",
    fontWeight: "bold",
    "@media (min-width:1024px)": {
      fontSize: "1.7rem",
      lineHeight: "2rem",
      maxWidth: "26rem",
    },
  },
  button: {
    background: theme.colours.white,
    borderRadius: 50,
    border: 0,
    color: "#455597",
    boxShadow: "2px 2px 10px 0 #395d4948",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    width: "204px",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  columnRight: {
    zIndex: 1,
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    maxWidth: "90vw",
    objectFit: "contain",
    paddingBottom: 10,
    [theme.breakpoints.up("lg")]: {
      paddingTop: "4em",
    },
    "@media (min-width:1280px)": {
      width: "50%",
    },
  },
  peopleImage: {
    width: "100%",
    maxWidth: "90vw",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "4em",
    },
  },
  //steps section
  stepsView: {
    background: "linear-gradient(to bottom, #fff, #f0f2ff)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleSteps: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#2f2f81",
    paddingTop: 80,
    paddingBottom: 10,
  },
  subtitleSteps: {
    textAlign: "center",
    color: theme.colours.violeta,
    fontWeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },
  steps: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    margin: "auto",
    maxWidth: 1200,
    paddingTop: 57,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 57,
  },
  stepItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 200,
  },
  stepsIconText: {
    color: theme.colours.violeta,
    fontWeight: "bold",
    maxWidth: 140,
    paddingTop: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  //Aseguradoras section
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "auto",
    padding: 20,
    paddingTop: 80,
    paddingBottom: 80,
    backgroundColor: theme.colours.violeta,
  },
  title: {
    color: theme.colours.white,
    fontWeight: "bold",
    paddingBottom: 20,
    fontSize: "1.7rem",
  },
  subtitle: {
    color: theme.colours.white,
    textAlign: "center",
    maxWidth: 550,
  },
  sponsors: {
    display: "flex",
    flexWrap: "wrap",
    objectFit: "contain",
    maxWidth: 800,
    paddingTop: 30,
    paddingBottom: 30,
    justifyContent: "center",
  },
  sponsorIcon: {
    objectFit: "contain",
    width: "15em",
    padding: 20,
  },
}));

const CAMPANIA_PUBLICITARIA_SOURCE = "source";
const SIN_PUBLICIDAD = "organico";

const Home = ({ location }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const source = getUrlParam(location, CAMPANIA_PUBLICITARIA_SOURCE);

    const campana = source ? source : SIN_PUBLICIDAD;

    dispatch(setSocketId(localStorage.getItem("userId")));
    dispatch(setCampaniaPublicitariaSource(campana));
  }, [location, dispatch]);

  const handleCotizarButton = () => {
    dispatch(nuevaCotizacionHomeButton());
  };

  const classes = useStyles();
  return (
    <Fragment>
      <TopBarHome />
      <div className={classes.root}>
        <div className={classes.waveContainer}>
          <div className={classes.headerContainer}>
            <div className={classes.columnLeft}>
              <Typography variant={"h3"} className={classes.titleHeader}>
                {"Encontrá el mejor seguro para tu auto"}
              </Typography>
              <Typography variant={"h5"} className={classes.subtitleHeader}>
                {"Cotizá, contratá y asegurate al mejor precio"}
                <span className={classes.subtitleHeaderBold}>
                  &nbsp;{"¡en un toque!"}
                </span>
              </Typography>
              <div style={{ paddingTop: 30, maxWidth: 200 }}>
                <Button
                  className={classes.button}
                  onClick={handleCotizarButton}
                  to={"/seguros/marca"}
                  variant="contained"
                  fullWidth
                  component={Link}
                >
                  COTIZAR
                </Button>
              </div>
            </div>
            <div className={classes.columnRight}>
              <img className={classes.peopleImage} src={people} alt="" />
            </div>
          </div>
        </div>

        <div className={classes.stepsView}>
          <div style={{ width: "97vw" }}>
            <Typography variant={"h4"} className={classes.titleSteps}>
              {"Tres simples pasos"}
            </Typography>
          </div>
          <div style={{ width: "80vw" }}>
            <Typography variant={"subtitle1"} className={classes.subtitleSteps}>
              {"para asegurar tu vehículo de la manera más práctica"}
            </Typography>
          </div>
          <div className={classes.steps}>
            <StepItem
              icon={iconCarAnimation}
              value={"Completá los datos de tu auto."}
            />
            <StepItem
              icon={iconPersonAnimation}
              value={"Completá tus datos personales."}
            />
            <StepItem
              icon={iconSeguroAnimation}
              value={"Elegí el seguro que más te conviene."}
            />
          </div>
        </div>

        <div className={classes.container}>
          <Typography
            variant={"h4"}
            color="textPrimary"
            className={classes.title}
          >
            Las mejores opciones
          </Typography>
          <Typography
            variant={"h6"}
            color="textPrimary"
            className={classes.subtitle}
          >
            Trabajamos con las aseguradoras líderes del mercado para que
            encuentres la opción más conveniente
          </Typography>
          <div className={classes.sponsors}>
            <img src={berkleyLogo} className={classes.sponsorIcon} alt="" />
            <img src={sancorLogo} className={classes.sponsorIcon} alt="" />
            <img src={zurichLogo} className={classes.sponsorIcon} alt="" />
            <img
              src={mercantilAndinaLogo}
              className={classes.sponsorIcon}
              alt=""
            />
            <img src={triunfoLogo} className={classes.sponsorIcon} alt="" />
            <img src={rioLogo} className={classes.sponsorIcon} alt="" />
          </div>
        </div>
        <div id="footer" name="footer">
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

const StepItem = ({ icon, value }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(nuevaCotizacionIcon());
  };

  return (
    <div className={classes.stepItem}>
      <Link
        to={"/seguros/marca"}
        onClick={handleClick}
        style={{ textTransform: "none" }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: icon,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={140}
          width={140}
        />
      </Link>
      <Typography variant={"subtitle1"} className={classes.stepsIconText}>
        {value}
      </Typography>
    </div>
  );
};
