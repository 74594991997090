const environment = {
  name: process.env.REACT_APP_ENV,

  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  segurosUrl: process.env.REACT_APP_API_SEGUROS_URL,
  vehiclesUrl: process.env.REACT_APP_API_VEHICLES_URL,
  localhostUrl: "http://localhost:5111",

  awsLogs: {
    apiKey: process.env.REACT_APP_AWS_LOGS_API_KEY,
    apiSecret: process.env.REACT_APP_AWS_LOGS_API_SECRET,
  },

  aplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,
};

export default environment;
