import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { vehiculos, provincias } from "~api/catalogos";
import { seguros } from "~api/seguros";
import errorsMiddleware from "./middlewares/errorsMiddleware";
import analyticsMiddleware from "./middlewares/analyticsMiddleware";

const defaultServices = {
  api: {
    vehiculos,
    provincias,
    seguros
  }
};

export default function configureStore(
  initialState,
  services = defaultServices
) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunk.withExtraArgument(services),
        errorsMiddleware,
        analyticsMiddleware
      )
    )
  );
}
