import { createAction, handleActions } from "redux-actions";

export const setCampaniaPublicitariaSource = createAction(
  "SET_CAMPANIA_PUBLICITARIA_SOURCE",
  source => ({ source }),
  () => ({ doNotSendToAnalytics: true })
);

export const setSocketId = createAction("SET_SOCKET_ID", socketId => ({
  socketId
}));

const initialState = {
  socketId: localStorage.getItem("userId"),
  campaniaPublicitariaSource: null
};

const appReducer = handleActions(
  {
    [setCampaniaPublicitariaSource]: (state, action) => {
      return {
        ...state,
        campaniaPublicitariaSource: !state.campaniaPublicitariaSource
          ? action.payload.source
          : state.campaniaPublicitariaSource
      };
    },
    [setSocketId]: (state, action) => {
      return {
        ...state,
        socketId: action.payload.socketId
      };
    }
  },
  initialState
);

export default appReducer;
