import { logEventAnalytics } from "~libs/amplitudeHelper";
import uuidv4 from "uuid/v4";

const analyticsMiddleware = store => next => action => {
  try {
    console.debug("Analytics track", action.type, action.payload);

    const sendToAnalytics = !(action.meta && action.meta.doNotSendToAnalytics);
    const id = localStorage.getItem("userId");

    if (!id) localStorage.setItem("userId", uuidv4());

    //console.log(store.getState().appReducer.campaniaPublicitariaSource);
    //console.log(localStorage.getItem("userId"));

    if (sendToAnalytics) {
      logEventAnalytics(
        action.type,
        action.payload
          ? {
              ...action.payload,
              campaniaPublicitariaSource: store.getState().appReducer
                .campaniaPublicitariaSource,
              userId: localStorage.getItem("userId")
            }
          : null
      );
    }
  } catch (error) {
    console.error("Analytics error", error);
  }

  return next(action);
};

export default analyticsMiddleware;
