import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import useReactRouter from "use-react-router";
import { useSelector } from "react-redux";
import MarcaStep from "./datosVehiculoStep/MarcaStep";
import ModeloStep from "./datosVehiculoStep/ModeloStep";
import AnioStep from "./datosVehiculoStep/AnioStep";
import ProvinciaStep from "./datosVehiculoStep/ProvinciaStep";
import CodigoPostalStep from "./datosVehiculoStep/CodigoPostalStep";
import IngresaTusDatosStep from "./ingresaTusDatosStep/IngresaTusDatosStep";
import AgradecimientoStep from "./agradecimientoStep/AgradecimientoStep";
import { useDispatch } from "react-redux";
import { postPedidoCotizacion } from "./actions";
import { getCurrentStep } from "./CotizadorSeguroStepper";
import PolizasStep from "./polizasStep/PolizasStep";
import PolizaDetailStep from "./polizaDetailStep/PolizaDetailStep";
import useScrollToTop from "~shared/scrollToTop/ScrollToTop";

const useReduxSelector = () =>
  useSelector((state) => ({
    marcaSelected: state.datosVehiculoReducer.marcaSelected,
    modeloSelected: state.datosVehiculoReducer.modeloSelected,
    anioSelected: state.datosVehiculoReducer.anioSelected,
    provinciaSelected: state.datosVehiculoReducer.provinciaSelected,
    codigoPostalSelected: state.datosVehiculoReducer.codigoPostalSelected,

    polizaSelected: state.cotizadorSeguroReducer.polizaSelected,

    ingresaTusDatosConfirmed: state.agregarDatosPersonaReducer.confirmed,

    currentPoliza: state.polizaDetailSegurosReducer.currentPoliza,
    showDetail: state.polizaDetailSegurosReducer.showDetail,
  }));

const CotizadorSeguroForm = () => {
  const dispatch = useDispatch();
  const { history, location } = useReactRouter();
  const currentState = useReduxSelector();
  const {
    modeloSelected,
    anioSelected,
    codigoPostalSelected,
    currentPoliza,
    provinciaSelected,
  } = currentState;

  const stepper = getCurrentStep(currentState);
  let currentUrl = stepper.getStep();

  useEffect(() => {
    const isThisUrl = location.pathname === currentUrl;
    //avoid pushing url when back url is rendered
    if (!isThisUrl) {
      history.push(currentUrl);
    }
    //    goForward = goForward === null || location.pathname !== currentUrl;

    // eslint-disable-next-line
  }, [currentUrl]);

  useEffect(() => {
    const isComplete =
      modeloSelected.value && anioSelected.value && codigoPostalSelected.value;

    if (isComplete)
      dispatch(
        postPedidoCotizacion(
          modeloSelected.value,
          anioSelected.value,
          codigoPostalSelected.value,
          provinciaSelected.value
        )
      );
  }, [
    dispatch,
    modeloSelected.value,
    anioSelected.value,
    codigoPostalSelected.value,
    provinciaSelected.value,
  ]);

  useScrollToTop();

  return (
    <Switch>
      <Route exact path="/seguros/marca">
        <MarcaStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/anio">
        <AnioStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/modelo">
        <ModeloStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/provincia">
        <ProvinciaStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/codigoPostal">
        <CodigoPostalStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/contacto">
        <IngresaTusDatosStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/polizas">
        <PolizasStep title={stepper.getTitle()} />
      </Route>
      <Route exact path="/seguros/detalle">
        <PolizaDetailStep title={stepper.getTitle()} poliza={currentPoliza} />
      </Route>
      <Route exact path="/seguros/final">
        <AgradecimientoStep title={stepper.getTitle()} />
      </Route>
    </Switch>
  );
};

export default CotizadorSeguroForm;
