import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VehiculoMarcaSelector from "~shared/vehiculoMarcaSelector/VehiculoMarcaSelector";
import { selectMarca } from "./actions";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    marcaSelected: state.datosVehiculoReducer.marcaSelected
  }));

const MarcaStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { marcaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(selectMarca({ label: null, value: null }));
  }, [dispatch]);

  const handleChange = event => {
    dispatch(selectMarca(event.target.value));
  };

  return (
    <>
      <TopBar title={"Elegí la marca"} />
      <SlideCustom backNav={marcaSelected.label}>
        <div className={classes.cotizador}>
          <VehiculoMarcaSelector
            value={marcaSelected}
            onChange={handleChange}
          />
        </div>
      </SlideCustom>
    </>
  );
});

export default MarcaStep;
