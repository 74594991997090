import { createAction, handleActions } from "redux-actions";
import { setInitialStatePolizas } from "../cotizadorSeguro/polizasStep/polizaSelector/actions";
import { setInitialState as setInitialStateDatosVehiculo } from "../cotizadorSeguro/datosVehiculoStep/actions";
import { setInitialState as setInitialStateDatosPersona } from "../cotizadorSeguro/ingresaTusDatosStep/actions";
import { setInitialStatePolizaDetails } from "../cotizadorSeguro/polizaDetailStep/actions";

const setInitialStateCotizador = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_COTIZADOR",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const nuevaCotizacionMainButton = createAction(
  "NUEVA_COTIZACION_DESDE_BOTON_PRINCIPAL"
);

const nuevaCotizacionClicked = createAction("NUEVA_COTIZACION_DESDE_PASOS");

const nuevaCotizacionButtonTopBar = createAction(
  "NUEVA_COTIZACION_DESDE_TOP_BAR"
);

const nuevaCotizacionButtonTopBarMenu = createAction(
  "NUEVA_COTIZACION_DESDE_MENU_TOP_BAR"
);

const nuevaCotizacion = () => dispatch => {
  dispatch(setInitialStateCotizador());
  dispatch(setInitialStateDatosVehiculo());
  dispatch(setInitialStateDatosPersona());
  dispatch(setInitialStatePolizas());
  dispatch(setInitialStatePolizaDetails());
};

const nuevaCotizacionIcon = () => dispatch => {
  dispatch(nuevaCotizacionClicked());
  dispatch(nuevaCotizacion());
};

const nuevaCotizacionTopBarMenu = () => dispatch => {
  dispatch(nuevaCotizacionButtonTopBarMenu());
  dispatch(nuevaCotizacion());
};

const nuevaCotizacionTopBar = () => dispatch => {
  dispatch(nuevaCotizacionButtonTopBar());
  dispatch(nuevaCotizacion());
};

const nuevaCotizacionHomeButton = () => dispatch => {
  dispatch(nuevaCotizacionMainButton());
  dispatch(nuevaCotizacion());
};

const initialState = {};

const HomeReducer = handleActions({}, initialState);

export default HomeReducer;

export {
  nuevaCotizacion,
  nuevaCotizacionHomeButton,
  nuevaCotizacionIcon,
  nuevaCotizacionTopBar,
  nuevaCotizacionTopBarMenu
};
