import React from "react";
import { useSelector } from "react-redux";
import Slide from "@material-ui/core/Slide";

const useReduxSelector = () =>
  useSelector(state => ({
    backClicked: state.cotizadorSeguroReducer.backClicked
  }));

const SlideCustom = ({ children, backNav }) => {
  const { backClicked } = useReduxSelector();

  return (
    <Slide direction={!backNav && !backClicked ? "left" : "right"} in={true}>
      {children}
    </Slide>
  );
};
export default SlideCustom;
