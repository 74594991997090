import { createAction, handleActions } from "redux-actions";
import { postSolicitudesPoliza } from "../polizaDetailStep/actions";

const setInitialState = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_DATOS_PERSONA",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const selectNombre = createAction(
  "AGREGAR_DATOS_PERSONA_NOMBRE_SELECT",
  value => ({ value })
);

const selectEmail = createAction(
  "AGREGAR_DATOS_PERSONA_EMAIL_SELECT",
  value => ({ value })
);

const selectTelefono = createAction(
  "AGREGAR_DATOS_PERSONA_TELEFONO_SELECT",
  value => ({ value })
);

const selectConfirm = createAction(
  "AGREGAR_DATOS_PERSONA_CONFIRM_SELECT",
  confirmed => ({ confirmed })
);

const putEnvioDatosPersonaInited = createAction(
  "VEHICULOS_PUT_ENVIO_DATOS_PERSONA_INITED"
);
const putEnvioDatosPersonaCompleted = createAction(
  "VEHICULOS_PUT_ENVIO_DATOS_PERSONA_COMPLETED"
);
const putEnvioDatosPersonaFailed = createAction(
  "VEHICULOS_PUT_ENVIO_DATOS_PERSONA_FAILED",
  error => ({ error })
);

const putEnvioDatosPersona = (
  nombre,
  email,
  telefono,
  cotizacionId,
  cotizacionPlanId
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        putEnvioDatosPersonaInited({ nombre, email, telefono, cotizacionId })
      );

      const data = { nombre, email, telefono };

      await services.api.seguros().putCotizacion(cotizacionId, data);

      dispatch(postSolicitudesPoliza(cotizacionId, cotizacionPlanId));

      dispatch(putEnvioDatosPersonaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(putEnvioDatosPersonaFailed(error));
    }
  };
};

const initialState = {
  nombreSelected: { value: "", valid: false },
  emailSelected: { value: "", valid: false },
  telefonoSelected: { value: "", valid: false },
  confirmed: false
};

const agregarDatosPersonaReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...initialState
      };
    },
    [selectNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectConfirm]: (state, action) => {
      return {
        ...state,
        confirmed: action.payload.confirmed
      };
    }
  },
  initialState
);

export default agregarDatosPersonaReducer;
export {
  setInitialState,
  selectNombre,
  selectEmail,
  selectTelefono,
  selectConfirm,
  putEnvioDatosPersona
};
