import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { nuevaCotizacionTopBarMenu } from "../../home/actions";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles(theme => ({
  root: { padding: "16px 8px" },
  dialog: { backgroundColor: "#fafafa", opacity: "0.9", zIndex: 2 },
  appBar: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    width: "85vw"
  },
  link: {
    color: theme.colours.black,
    fontWeigth: "bold",
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(24),
    width: "100%"
  },
  linksContainer: {
    display: "none",
    "@media (min-width:768px)": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%"
    }
  },
  button: {
    width: "50%",
    height: "38px",
    borderRadius: "23px",
    boxShadow: "2px 2px 10px 0 rgba(57, 93, 73, 0.28)",
    border: `solid 1px ${theme.colours.black}`,
    color: theme.colours.black,
    textTransform: "none",
    "@media (min-width:768px)": {
      marginRight: 20
    }
  },
  textCotizar: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 3,
    letterSpacing: "normal",
    textAlign: "left"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Menu = ({ open, onClose, onClick }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleCotizarButton = () => {
    dispatch(nuevaCotizacionTopBarMenu());
  };

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <div className={classes.root}>
        <div className={classes.appBar}>
          <IconButton
            edge="start"
            color="inherit"
            style={{ padding: 0 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List>
          <Divider style={{ color: "#000000" }} />
          <ListItem button onClick={onClose}>
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.mg-group.com.ar/"
            >
              Quiénes somos
            </a>
          </ListItem>
          <Divider style={{ color: "#000000" }} />
          <ListItem button onClick={onClose}>
            <HashLink className={classes.link} to="/#footer">
              Contacto
            </HashLink>
          </ListItem>
          <Divider style={{ color: "#000000" }} />

          <div style={{ display: "flex", paddingTop: 20 }}>
            <ListItem>
              <Button
                className={classes.button}
                onClick={handleCotizarButton}
                to={"/seguros/marca"}
                fullWidth
                component={Link}
              >
                <Typography className={classes.textCotizar}>cotizar</Typography>
              </Button>
            </ListItem>
          </div>
        </List>
      </div>
    </Dialog>
  );
};

export default Menu;
