import React, { memo, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import { setAnio } from "./actions";
import VehiculoAnioSelector from "~shared/vehiculoAnioSelector/VehiculoAnioSelector";
import TopBar from "~shared/TopBar";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    anioSelected: state.datosVehiculoReducer.anioSelected
  }));

const AnioStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { anioSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(setAnio({ label: null, value: null }));
  }, [dispatch]);

  const handleChange = event => {
    dispatch(setAnio(event.target.value));
  };

  return (
    <>
      <TopBar title={"Elegí el año"} />
      <SlideCustom backNav={anioSelected.label}>
        <div className={classes.cotizador}>
          <VehiculoAnioSelector value={anioSelected} onChange={handleChange} />
        </div>
      </SlideCustom>
    </>
  );
});

export default AnioStep;
