import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import agradecimiento from "~images/agradecimiento.svg";
import Footer from "~shared/footer/Footer";
import TopBar from "~shared/TopBar";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "linear-gradient(#ffffff,#f0f2ff)"
  },
  header: {
    padding: 20
  },
  imageContainer: {
    marginTop: 40,
    maxWidth: 500,
    height: 214,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  title: {
    marginTop: 40,
    fontWeight: 800,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.74",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.colours.violeta,
    fontSize: "2.5rem"
  },
  subtitle: {
    display: "flex",
    margin: "auto",
    fontWeight: 600,
    maxWidth: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.4",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#625e5e",
    fontSize: "1.3rem",
    paddingBottom: 50
  }
}));

const AgradecimientoStep = ({ backAction, title }) => {
  const classes = useStyles();

  return (
    <>
      <TopBar backAction={backAction} title={title} isUrl />
      <SlideCustom>
        <div className={classes.cotizadorNoPadding}>
          <div className={classes.root}>
            <div className={classes.header}>
              <div className={classes.imageContainer}>
                <img src={agradecimiento} alt="" />
              </div>
              <Typography color="textPrimary" className={classes.title}>
                {"¡Gracias!"}
              </Typography>
              <Typography color="textPrimary" className={classes.subtitle}>
                {
                  "En breve nos comunicaremos con vos para finalizar el proceso."
                }
              </Typography>
            </div>
          </div>
          <Footer />
        </div>
      </SlideCustom>
    </>
  );
};

export default AgradecimientoStep;
