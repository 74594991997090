import { createAction, handleActions } from "redux-actions";

const setInitialStatePolizas = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_DATOS_POLIZAS",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const getPolizasSegurosInited = createAction(
  "POLIZA_GET_POLIZAS_SEGUROS_INITED"
);

const getPolizasSegurosCompleted = createAction(
  "POLIZA_GET_POLIZAS_SEGUROS_COMPLETED",
  response => {
    let polizas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      polizas = response.companias.sort((x, y) => {
        return parseInt(x.orden) > parseInt(y.orden) ? 1 : -1;
      });
    }

    return {
      polizas
    };
  }
);

const getPolizasSegurosFailed = createAction(
  "POLIZA_GET_POLIZAS_SEGUROS_FAILED",
  error => ({ error })
);

const sendPolizaReceivedToAnalytics = createAction(
  "POLIZA_GET_POLIZAS_SEGUROS_POLIZA_RECIBIDA",
  (poliza, ordenRecepcion, totalPolizas) => {
    const polizaRecibida = {
      compania: poliza.compania,
      companiaId: poliza.companiaId,
      orden: poliza.orden,
      cotizaciones: poliza.cotizaciones.map(cotizacion => {
        return cotizacion.cotizacionPlanId;
      })
    };

    return { polizaRecibida, ordenRecepcion, totalPolizas };
  }
);

const getPolizasSeguros = id => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizasSegurosInited({ id }));

      const response = await services.api.seguros().getPolizas(id);

      const currentId = getState().cotizadorSeguroReducer.cotizacionId;
      console.log(currentId, response.cotizacionId);
      //avoid reload polizas list with a previous request
      if (currentId && currentId === response.cotizacionId)
        dispatch(getPolizasSegurosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizasSegurosFailed(error));
    }
  };
};

const initialState = {
  polizas: [
    {
      companiaId: "loading-1",
      compania: null,
      orden: null,
      estado: "pendiente",
      cotizaciones: []
    },
    {
      companiaId: "loading-2",
      compania: null,
      orden: null,
      estado: "pendiente",
      cotizaciones: []
    },
    {
      companiaId: "loading-3",
      compania: null,
      orden: null,
      estado: "pendiente",
      cotizaciones: []
    }
  ],
  polizasAnalytics: [],
  polizasRequestCount: 1
};
const polizaSegurosReducer = handleActions(
  {
    [setInitialStatePolizas]: (state, action) => {
      return {
        ...initialState
      };
    },
    [getPolizasSegurosInited]: (state, action) => {
      return {
        ...state
      };
    },
    [getPolizasSegurosCompleted]: (state, action) => {
      return {
        ...state,
        polizas: [...action.payload.polizas],
        polizasRequestCount: state.polizasRequestCount + 1
      };
    },
    [getPolizasSegurosFailed]: (state, action) => {
      return {
        ...state
      };
    },
    [sendPolizaReceivedToAnalytics]: (state, action) => {
      return {
        ...state
      };
    }
  },

  initialState
);

export default polizaSegurosReducer;
export {
  getPolizasSeguros,
  setInitialStatePolizas,
  sendPolizaReceivedToAnalytics
};
