import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VehiculoModeloSelector from "~shared/vehiculoModeloSelector/vehiculoModeloSelector";
import { setModelo } from "./actions";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    modeloSelected: state.datosVehiculoReducer.modeloSelected,
    marcaSelected: state.datosVehiculoReducer.marcaSelected,
    anioSelected: state.datosVehiculoReducer.anioSelected
  }));

const ModeloStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { modeloSelected, marcaSelected, anioSelected } = useReduxSelector();

  const handleChange = event => {
    dispatch(setModelo(event.target.value));
  };

  useEffect(() => {
    dispatch(setModelo({ label: null, value: null }));
  }, [dispatch]);

  return (
    <>
      <TopBar title={"Elegí el modelo"} />
      <SlideCustom backNav={modeloSelected.label}>
        <div className={classes.cotizador}>
          <VehiculoModeloSelector
            value={modeloSelected}
            marca={marcaSelected}
            anio={anioSelected}
            onChange={handleChange}
          />
        </div>
      </SlideCustom>
    </>
  );
});

export default ModeloStep;
