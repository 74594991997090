import React, { Fragment } from "react";
import useReactRouter from "use-react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import back from "~images/back.svg";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
  "@global body": {
    background: "#f7f8ff"
  },
  header: {
    display: "flex",
    flexGrow: 1,
    textAlign: "center",
    background: theme.colours.violeta,
    boxShadow: "2px 2px 10px 0 #CCCCE4"
  },
  title: {
    flexGrow: 1
  }
}));

const TopBar = ({ title }) => {
  const { history } = useReactRouter();
  const classes = useStyles();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <HideOnScroll>
        <AppBar className={classes.header}>
          <Toolbar style={{ padding: 5 }}>
            <IconButton onClick={handleBack}>
              <img src={back} alt="" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <div style={{ width: 48 }} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </Fragment>
  );
};

export default TopBar;

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
