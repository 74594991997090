const TC = "TCBase";
const TP = "TCPremium";
const TR = "TR";

const tipoPoliza = {
  //[TC]: "TERCEROS COMPLETO",
  [TC]: "INCENDIO TOTAL Y ROBO",
  [TP]: "TERCEROS PREMIUM",
  [TR]: "TODO RIESGO"
};

export { TC, TP, TR, tipoPoliza };
