import React, { memo, useRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Next from "@material-ui/icons/ArrowForward";
import Divider from "@material-ui/core/Divider";
import ContentLoader from "react-content-loader";
import InfiniteScroll from "react-infinite-scroller";

const useStyles = makeStyles(theme => ({
  listPaper: {
    borderRadius: "0 0 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4"
  },
  root: {
    maxHeight: "calc(100vh - 220px);",
    overflow: "auto",
    width: "100%"
  },
  iconButton: {
    color: theme.colours.violeta,
    padding: 0
  },
  textItem: {
    fontSize: 16
  }
}));

const emptyListText = "No hay resultados.";

const ListView = memo(({ list, selectItem, isLoading }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const classes = useStyles();

  const listRef = useRef(null);

  useEffect(() => {
    const nextPage = getPage(list, 1);
    setItems(nextPage);
    setHasMore(true);
  }, [list]);

  const loadMore = page => {
    const nextPage = getPage(list, page);
    setItems([...items, ...nextPage]);
    setHasMore(nextPage.length > 0);
  };

  return (
    <Paper className={classes.listPaper}>
      <List innerRef={listRef} className={classes.root}>
        {(isLoading || list.length > 0) && <Divider />}
        {isLoading && <LoadingTemplate length={5} />}
        {!isLoading && list.length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<LoadingTemplate key={"loading-infinite"} length={1} />}
            useWindow={false}
            getScrollParent={() => listRef.current}
          >
            {items.map(item => (
              <ListViewItem
                key={`lisViewItem-${item.value}`}
                value={item.value}
                label={item.label}
                selectItem={selectItem}
              />
            ))}
          </InfiniteScroll>
        )}
        {!isLoading && list.length === 0 && (
          <ListItem className={classes.item}>
            <ListItemText
              className={classes.textItem}
              primary={emptyListText}
              disableTypography
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
});

export default ListView;

const ListViewItem = memo(({ value, label, selectItem }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      divider
      onClick={() => {
        selectItem({ value, label });
      }}
    >
      <ListItemText
        className={classes.textItem}
        primary={label}
        disableTypography
      />
      <IconButton className={classes.iconButton} disabled={true}>
        <Next />
      </IconButton>
    </ListItem>
  );
});

const LoadingTemplate = ({ length }) => {
  const classes = useStyles();

  return Array.apply(null, Array(length)).map((_, index) => (
    <ListItem key={`listViewLoadingItem-${index}`} divider>
      <ListItemText
        primary={
          <ContentLoader
            height={10}
            width={316}
            speed={1}
            backgroundColor="#f7f8ff"
            foregroundColor="#d9e8df"
          >
            <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
            <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
            <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />
          </ContentLoader>
        }
        disableTypography
      />
      <IconButton className={classes.iconButton} disabled={true}>
        <Next />
      </IconButton>
    </ListItem>
  ));
};

const getPage = (list, page) => {
  const pageCount = 30;

  const begin = (page - 1) * pageCount;
  const end =
    page * pageCount > list.length - 1 ? list.length - 1 : page * pageCount;

  if (list && list.length > 0 && list.length > begin)
    return list.slice(begin, end);

  return [];
};
