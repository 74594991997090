import { createAction, handleActions } from "redux-actions";
import { setInitialStatePolizas } from "../polizasStep/polizaSelector/actions";

const setInitialState = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_DATOS_VEHICULO",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const selectModelo = createAction(
  "SEGUROS_COTIZADOR_MODELO_SELECT",
  modelo => ({ modelo })
);

const selectMarca = createAction("SEGUROS_COTIZADOR_MARCA_SELECT", marca => ({
  marca
}));

const selectAnio = createAction("SEGUROS_COTIZADOR_ANIO_SELECT", anio => ({
  anio
}));

const selectProvincia = createAction(
  "SEGUROS_COTIZADOR_PROVINCIA_SELECT",
  provincia => ({ provincia })
);

const selectCodigoPostal = createAction(
  "SEGUROS_COTIZADOR_CODIGOPOSTAL_SELECT",
  codigoPostal => ({ codigoPostal })
);

const setAnio = anio => (dispatch, getState, services) => {
  dispatch(selectAnio(anio));
  dispatch(setInitialStatePolizas());
};

const setModelo = modelo => (dispatch, getState, services) => {
  dispatch(selectModelo(modelo));
  dispatch(setInitialStatePolizas());
};

const setCodigoPostal = codigoPostal => (dispatch, getState, services) => {
  dispatch(selectCodigoPostal(codigoPostal));
  dispatch(setInitialStatePolizas());
};

const initialState = {
  marcaSelected: { value: null, label: null },
  modeloSelected: { value: null, label: null },
  anioSelected: { value: null, label: null },
  provinciaSelected: { value: null, label: null },
  codigoPostalSelected: { value: null, label: null }
};

const datosVehiculoReducer = handleActions(
  {
    [setInitialState]: (state, action) => {
      return {
        ...initialState
      };
    },
    [selectMarca]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        modeloSelected: { value: null, label: null },
        marcaSelected: action.payload.marca
      };
    },
    [selectAnio]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        modeloSelected: { value: null, label: null },
        anioSelected: action.payload.anio
      };
    },
    [selectModelo]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        modeloSelected: action.payload.modelo
      };
    },
    [selectProvincia]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: { value: null, label: null },
        provinciaSelected: action.payload.provincia
      };
    },
    [selectCodigoPostal]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: action.payload.codigoPostal
      };
    }
  },
  initialState
);

export default datosVehiculoReducer;
export {
  setInitialState,
  selectMarca,
  selectProvincia,
  setAnio,
  setModelo,
  setCodigoPostal
};
