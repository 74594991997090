import React, { useRef, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  searchPaper: {
    display: "flex",
    borderRadius: 8,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    transition: "all 2s ease"
  },
  searchPaperBottomSquared: {
    borderRadius: "8px 8px 0 0"
  },
  input: {
    flex: 1
  },
  iconSearch: {
    color: theme.colours.grey2,
    padding: 10,
    width: 44,
    height: 44
  },
  iconButton: {
    color: theme.colours.violeta,
    padding: 10,
    width: 44,
    height: 44
  }
}));

const Searcher = ({
  filter,
  onChange,
  onClear,
  placeholder,
  borderBottomRounded
}) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState(filter);

  let timer = null;

  const handleChange = event => {
    clearTimeout(timer);
    const value = event.target.value;
    setValue(value);
    timer = setTimeout(() => {
      if (onChange) onChange({ target: { value } });
    }, 200);
  };

  const handleClear = () => {
    setValue("");
    if (onClear) onClear();
  };

  const classes = useStyles();

  const filtering = value && value.length > 0;

  return (
    <Paper
      className={
        filtering || !borderBottomRounded
          ? [classes.searchPaper, classes.searchPaperBottomSquared].join(" ")
          : classes.searchPaper
      }
    >
      <div
        onClick={() => {
          if (inputRef.current) inputRef.current.focus();
        }}
        className={classes.iconSearch}
      >
        <SearchIcon />
      </div>
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {filtering && (
        <IconButton className={classes.iconButton} onClick={handleClear}>
          <Close />
        </IconButton>
      )}
    </Paper>
  );
};

export default Searcher;
