const marcaId = "marca";
const anioId = "anio";
const modeloId = "modelo";
const provinciaId = "provincia";
const codigoPostalId = "codigoPostal";
const ingresaTusDatosId = "contacto";
const elegiTuPolizaId = "polizas";
const detallePolizaId = "detalle";
const graciasId = "final";

const steps = {
  [marcaId]: { title: "Elegí la marca" },
  [anioId]: { title: "Elegí el año" },
  [modeloId]: { title: "Elegí el modelo" },
  [provinciaId]: { title: "¿Está radicado en?" },
  [codigoPostalId]: { title: "Elegí el código postal" },
  [ingresaTusDatosId]: { title: "Ingresá tus datos" },
  [elegiTuPolizaId]: { title: "Elegí una Póliza" },
  [detallePolizaId]: { title: "Elegí una Póliza" },
  [graciasId]: { title: "" }
};

export const getCurrentStep = ({
  marcaSelected,
  modeloSelected,
  anioSelected,
  provinciaSelected,
  codigoPostalSelected,
  ingresaTusDatosConfirmed,
  polizaSelected,
  showDetail
}) => {
  const marcaStep = Step(marcaId, !marcaSelected.value);
  const anioStep = Step(anioId, !anioSelected.value);
  const modeloStep = Step(modeloId, !modeloSelected.value);
  const provinciaStep = Step(provinciaId, !provinciaSelected.value);
  const codigoPostalStep = Step(codigoPostalId, !codigoPostalSelected.value);
  const tusDatosStep = Step(ingresaTusDatosId, !ingresaTusDatosConfirmed);
  const eligeTuPolizaStep = Step(
    elegiTuPolizaId,
    !showDetail && !polizaSelected
  );
  const detallePolizaStep = Step(detallePolizaId, !polizaSelected);
  const confirmacionStep = Step(graciasId, true);

  //set order of steps
  marcaStep
    .next(anioStep)
    .next(modeloStep)
    .next(provinciaStep)
    .next(codigoPostalStep)
    .next(eligeTuPolizaStep)
    .next(detallePolizaStep)
    .next(tusDatosStep)
    .next(confirmacionStep);

  return marcaStep.run();
};

const Step = (id, isThisStep) => {
  let next = null;

  return {
    next(nextStep) {
      next = nextStep;
      return next;
    },
    getNext() {
      return next;
    },
    getStep() {
      return "/seguros/" + id;
    },
    getTitle() {
      return steps[id].title;
    },
    run() {
      return isThisStep ? this : next && next.run();
    }
  };
};
