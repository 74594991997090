import { combineReducers } from "redux";
import appReducer from "./appReducer";
import cotizadorSeguroReducer from "~components/cotizadorSeguro/actions";
import vehiculosMarcasReducer from "~shared/vehiculoMarcaSelector/actions";
import vehiculosModelosReducer from "~shared/vehiculoModeloSelector/actions";
import codigosPostalesReducer from "~shared/codigoPostalSelector/actions";
import agregarDatosPersonaReducer from "~components/cotizadorSeguro/ingresaTusDatosStep/actions";
import polizaSegurosReducer from "~components/cotizadorSeguro/polizasStep/polizaSelector/actions";
import polizaDetailSegurosReducer from "~components/cotizadorSeguro/polizaDetailStep/actions";
import datosVehiculoReducer from "~components/cotizadorSeguro/datosVehiculoStep/actions";
import snackbarReducer from "~shared/snackbarsProvider/actions";

const rootReducer = combineReducers({
  appReducer,
  cotizadorSeguroReducer,
  datosVehiculoReducer,
  vehiculosMarcasReducer,
  vehiculosModelosReducer,
  codigosPostalesReducer,
  agregarDatosPersonaReducer,
  polizaSegurosReducer,
  polizaDetailSegurosReducer,
  snackbarReducer
});

export default rootReducer;
