import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import isNullOrEmpty from "~libs/isNullOrEmpty";

const InputField = ({
  autoFocus,
  displayError,
  label,
  required,
  value,
  onChange
}) => {
  const [errorText, setErrorText] = useState("Requerido");
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleChange = event => {
    const validation = validateInput(event.target.value, required);

    setTouched(false);
    setValid(validation.valid);
    setErrorText(validation.error);

    if (onChange)
      onChange({
        target: {
          value: {
            value: event.target.value,
            valid: validation.valid
          }
        }
      });
  };

  const handleBlur = () => {
    const validation = validateInput(value, required);

    setTouched(true);
    setValid(validation.valid);
    setErrorText(validation.error);

    if (onChange)
      onChange({
        target: {
          value: {
            value: value,
            valid: validation.valid
          }
        }
      });
  };

  const showError = !valid && (displayError || touched);

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      helperText={showError ? errorText : ""}
      error={showError}
      label={label}
      autoFocus={autoFocus}
      fullWidth
    />
  );
};

export default InputField;

const validateInput = (value, isRequired) => {
  if (isNullOrEmpty(value) && isRequired)
    return { valid: false, error: "Requerido" };
  return { valid: true, error: "" };
};
