import React, { useState, Fragment, useEffect, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "../listView/listView";
import Searcher from "../searcher/searcher";
import filterItems from "~libs/filterItems";
import { useSelector, useDispatch } from "react-redux";
import { fetchVehiculosModelos } from "./actions";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
    height: "inherit",
    paddingTop: 3,
    paddingBottom: 3,
    whiteSpace: "inherit"
  },
  chipLabel: {
    whiteSpace: "inherit"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isLoading: state.vehiculosModelosReducer.isLoading,
    modelos: state.vehiculosModelosReducer.modelos
  }));

const VehiculoModeloSelector = memo(({ value, marca, anio, onChange }) => {
  const [filter, setFilter] = useState("");

  const { isLoading, modelos } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (marca.value && anio.value)
      dispatch(fetchVehiculosModelos(marca.value, anio.value));
  }, [dispatch, marca.value, anio.value]);

  const handleModelItem = value => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <Fragment>
      {!value.label && (
        <div className={classes.searcher}>
          <Searcher
            onClear={() => handleModelItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar modelo"}
          />
          <ListView
            isLoading={isLoading}
            selectItem={handleModelItem}
            list={filter === "" ? modelos : filterItems(modelos, filter)}
          />
        </div>
      )}
    </Fragment>
  );
});

export default VehiculoModeloSelector;
