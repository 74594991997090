import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import PolizaDetail from "./PolizaDetail";
import SlideCustom from "~shared/slideCustom/SlideCustom";
import { unselectPoliza } from "../actions";

const useStyles = makeStyles(theme => ({
  cotizadorNoPadding: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    maxWidth: 500
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    polizaSelected: state.cotizadorSeguroReducer.polizaSelected
  }));

const PolizaDetailStep = memo(({ poliza }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { polizaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(unselectPoliza());
  }, [dispatch]);

  return (
    <>
      {poliza && (
        <>
          <TopBar title={"Elegí una Póliza"} />
          <SlideCustom backNav={polizaSelected}>
            <div className={classes.cotizadorNoPadding}>
              <PolizaDetail poliza={poliza} />
            </div>
          </SlideCustom>
        </>
      )}
    </>
  );
});

export default PolizaDetailStep;
