import { createAction, handleActions } from "redux-actions";

const fetchCodigosPostalesInited = createAction(
  "VEHICULOS_CODIGOS_POSTALES_INITED"
);
const fetchCodigosPostalesCompleted = createAction(
  "VEHICULOS_CODIGOS_POSTALES_COMPLETED",
  response => {
    let codigosPostales = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      codigosPostales = response.codigosPostales.map(x => ({
        value: x,
        label: x
      }));
    }
    return { codigosPostales };
  }
);
const fetchCodigosPostalesFailed = createAction(
  "VEHICULOS_CODIGOS_POSTALES_FAILED",
  error => ({ error })
);

const fetchCodigosPostales = (provincia, localidad) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCodigosPostalesInited({ provincia, localidad }));

      let response;
      if (localidad) {
        response = await services.api
          .provincias()
          .getCodigosPostales(provincia, localidad);
      } else {
        response = await services.api
          .provincias()
          .getCodigosPostales(provincia);
      }

      dispatch(fetchCodigosPostalesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchCodigosPostalesFailed(error));
    }
  };
};

const initialState = { isLoading: false, codigosPostales: [] };

const codigosPostalesReducer = handleActions(
  {
    [fetchCodigosPostalesInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [fetchCodigosPostalesCompleted]: (state, action) => {
      return {
        ...state,
        codigosPostales: [...action.payload.codigosPostales],
        isLoading: false
      };
    },
    [fetchCodigosPostalesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default codigosPostalesReducer;
export { fetchCodigosPostales };
