import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import { getPolizasSeguros, sendPolizaReceivedToAnalytics } from "./actions";
import CompaniaGroup from "./CompaniaGroup";
import icon_tc_incendio from "~images/icon_tc_incendio.svg";
import icon_tp from "~images/icon_tp.svg";
import icon_tr from "~images/icon_tr.svg";
import EmptyState from "./emptyState";
import { TC, TP, TR } from "./tipoPoliza";
import Button from "@material-ui/core/Button";
import { selectPoliza } from "../../actions";
import {
  setCurrentPoliza,
  setInitialCurrentPoliza
} from "../../polizaDetailStep/actions";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1,
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
    background: theme.colours.white
  },
  iconTextContainer: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  headerColumn: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
    //borderBottom: "5px solid #45459c"
  },
  headerColumnMiddle: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    alignItems: "center"
    //borderBottom: "5px solid #6868bf"
  },
  divider: {
    width: 1,
    height: 90
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    paddingTop: 5,
    color: theme.colours.violeta,
    lineHeight: 1.1,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap"
  },
  polizasContainer: {
    paddingBottom: 56
  },
  contratar: {
    display: "flex",
    position: "absolute",
    bottom: "0",
    height: "56px",
    width: 250,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    borderRadius: 50,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    }
  }
}));

const PENDIENTE = "pendiente";
const COMPLETA = "completa";
const MAX_REQUESTS = 4;
const MAX_LOADING = 3;

const useReduxSelector = () =>
  useSelector(state => ({
    polizas: state.polizaSegurosReducer.polizas,
    polizasRequestCount: state.polizaSegurosReducer.polizasRequestCount,

    cotizacionId: state.cotizadorSeguroReducer.cotizacionId,

    currentPoliza: state.polizaDetailSegurosReducer.currentPoliza
  }));

const PolizaSelector = () => {
  const {
    cotizacionId,
    polizas,
    polizasRequestCount,
    currentPoliza
  } = useReduxSelector();
  const dispatch = useDispatch();

  const [polizasCompleted, setPolizasCompleted] = useState([]);
  const countComplete = getCountComplete(polizasCompleted);

  const isPending =
    polizasRequestCount <= MAX_REQUESTS &&
    getCountComplete(polizas) < polizas.length;

  useEffect(() => {
    console.log(polizasRequestCount);
    const timer = setTimeout(() => {
      if (isPending && cotizacionId) {
        dispatch(getPolizasSeguros(cotizacionId));
      }
    }, polizasRequestCount * 1000);
    return () => clearTimeout(timer);
  }, [dispatch, isPending, cotizacionId, polizasRequestCount]);

  const handleOpen = poliza => {
    dispatch(setCurrentPoliza(poliza));
  };

  const handleContratar = () => {
    dispatch(selectPoliza(currentPoliza, false));
  };

  useEffect(() => {
    let polizasConCotizaciones = polizas;

    //all completed and still without currentPoliza set
    if (!isPending && !currentPoliza)
      polizasConCotizaciones = getPolizasConCotizaciones(polizas);

    if (!currentPoliza && polizasConCotizaciones.length > 0) {
      const defaultPoliza = getDefaultPoliza(polizasConCotizaciones);

      dispatch(setInitialCurrentPoliza(defaultPoliza));
    }
  }, [isPending, currentPoliza, countComplete, polizas, dispatch]);

  useEffect(() => {
    const newCountComplete = getCountComplete(polizas);
    const hasChanged =
      countComplete !== newCountComplete ||
      polizasCompleted.length !== polizas.length;

    if (hasChanged)
      setPolizasCompleted(
        getPolizasCompleted(polizasCompleted, polizas, dispatch)
      );
  }, [countComplete, polizasCompleted, polizas, dispatch]);

  const countEmpty = getCountEmpty(polizasCompleted);

  const hasNotResults =
    (!isPending && countEmpty === polizas.length) ||
    (isPending && polizas.length > 0 && countEmpty === polizas.length);

  const classes = useStyles();
  return (
    <Fragment>
      {hasNotResults && <EmptyState />}
      {!hasNotResults && (
        <div
          style={{
            position: "relative",
            minHeight: "calc( 100vh - 120px )"
          }}
        >
          <Paper className={classes.headerContainer} elevation={1} square>
            <div className={classes.headerColumn}>
              <img src={icon_tc_incendio} alt="" />
              <Typography className={classes.headerTitle}>
                {"Incendio Total\ny Robo"}
              </Typography>
            </div>
            <div className={classes.headerColumnMiddle}>
              <Divider className={classes.divider} />
              <div className={classes.iconTextContainer}>
                <img src={icon_tp} alt="" />
                <Typography className={classes.headerTitle}>
                  {"Terceros\nPremium"}
                </Typography>
              </div>
              <Divider className={classes.divider} />
            </div>
            <div className={classes.headerColumn}>
              <img src={icon_tr} alt="" />
              <Typography className={classes.headerTitle}>
                {"Todo\nRiesgo"}
              </Typography>
            </div>
          </Paper>
          <div className={classes.polizasContainer}>
            {countComplete < MAX_LOADING && (
              <PolizaList
                polizas={polizasCompleted}
                count={MAX_LOADING}
                onPolizaSelected={handleOpen}
              />
            )}
            {countComplete >= MAX_LOADING &&
              countComplete < polizasCompleted.length && (
                <PolizaList
                  polizas={polizasCompleted}
                  count={countComplete + 1}
                  onPolizaSelected={handleOpen}
                />
              )}
            {countComplete === polizas.length && (
              <PolizaList
                polizas={polizasCompleted}
                count={countComplete}
                onPolizaSelected={handleOpen}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px"
            }}
          >
            <Button
              className={classes.contratar}
              onClick={handleContratar}
              variant="contained"
              color="primary"
              disabled={!currentPoliza}
            >
              QUIERO ESTA PÓLIZA
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default PolizaSelector;

const PolizaList = ({ polizas, count, onPolizaSelected }) => {
  const { polizasRequestCount } = useReduxSelector();
  return polizas.slice(0, count).map((compania, index) => {
    return (
      <CompaniaGroup
        key={compania.companiaId}
        isLoading={
          compania.estado === PENDIENTE && polizasRequestCount <= MAX_REQUESTS
        }
        compania={compania}
        onPolizaSelected={onPolizaSelected}
      />
    );
  });
};

const getPolizasCompleted = (polizasCompleted, polizas, dispatch) => {
  //polizas already loaded in previous GET
  const loadedPolizas = getComplete(polizasCompleted);

  //polizas loaded in the last GET
  const receivedPolizas = polizasListDifference(polizas, polizasCompleted);

  sendToAnalytics(dispatch, polizas, loadedPolizas, receivedPolizas);

  //union between previous polizas and the new ones
  let result = loadedPolizas.concat(receivedPolizas);

  result = result.sort((x, y) => {
    const inOrder =
      (x.estado === COMPLETA && y.estado === PENDIENTE) ||
      (x.estado === COMPLETA &&
        y.estado === COMPLETA &&
        x.cotizaciones.length > 0 &&
        y.cotizaciones.length === 0);
    return inOrder ? -1 : 1;
  });

  return result;
};

//USEFUL AND AUXILIAR FUNCTIONS RELATED TO POLIZAS
const polizasListDifference = (firstList, secondList) => {
  return firstList.filter(poliza => {
    const isPoliza = secondList.find(newPoliza => {
      return poliza.companiaId === newPoliza.companiaId;
    });
    return !isPoliza;
  });
};

const getComplete = list => {
  return list.filter(poliza => {
    return poliza.estado === COMPLETA;
  });
};

const getCountComplete = list => {
  return list.filter(poliza => {
    return poliza.estado === COMPLETA;
  }).length;
};

const getDefaultPoliza = polizas => {
  const polizaTC = polizas[0].cotizaciones.find(item => {
    return item.tipoPlanComercial === TC ? item : null;
  });
  const polizaTP = polizas[0].cotizaciones.find(item => {
    return item.tipoPlanComercial === TP ? item : null;
  });
  const polizaTR = polizas[0].cotizaciones.find(item => {
    return item.tipoPlanComercial === TR ? item : null;
  });

  const defaultPoliza = polizaTP ? polizaTP : polizaTC ? polizaTC : polizaTR;

  return defaultPoliza;
};

const getPolizasConCotizaciones = polizas => {
  return polizas.filter(poliza => {
    const polizaTC = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TC ? item : null;
    });
    const polizaTP = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TP ? item : null;
    });
    const polizaTR = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TR ? item : null;
    });
    const hasPoliza = polizaTC || polizaTP || polizaTR;
    return poliza.estado === COMPLETA && hasPoliza;
  });
};

const getCountEmpty = polizas => {
  return polizas.filter(poliza => {
    const polizaTC = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TC ? item : null;
    });
    const polizaTP = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TP ? item : null;
    });
    const polizaTR = poliza.cotizaciones.find(item => {
      return item.tipoPlanComercial === TR ? item : null;
    });
    const hasPoliza = polizaTC || polizaTP || polizaTR;
    return poliza.estado === COMPLETA && !hasPoliza;
  }).length;
};

const sendToAnalytics = (dispatch, polizas, loadedPolizas, newPolizas) => {
  const alreadySent = getComplete(newPolizas);
  alreadySent.map((poliza, index) => {
    const ordenRecepcion = index + loadedPolizas.length + 1;
    const totalPolizas = polizas.length;
    dispatch(
      sendPolizaReceivedToAnalytics(poliza, ordenRecepcion, totalPolizas)
    );
    return 1;
  });
};
