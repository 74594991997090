import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import emptyImage from "~images/empty.svg";
import Button from "@material-ui/core/Button";
import { nuevaCotizacion } from "~components/home/actions";
import { backToDatosVehiculos } from "../../actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  header: {
    background: "linear-gradient(#ffffff,#f0f2ff)",
    padding: "2vw"
  },
  imageContainer: {
    marginTop: 40,
    maxWidth: 500,
    height: "40vh",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  emptyImage: {
    height: "40vh"
  },
  title: {
    marginTop: 40,
    fontWeight: 800,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.74",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.colours.violeta,
    fontSize: "2.5rem"
  },
  subtitle: {
    display: "flex",
    margin: "auto",
    fontWeight: 600,
    maxWidth: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.4",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#625e5e",
    fontSize: "1.3rem",
    paddingBottom: 20
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: "2vh",
    paddingBottom: "2vh",
    alignItems: "center"
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    }
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    }
  }
}));

const EmptyState = () => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(backToDatosVehiculos());
  };
  const handleCotizar = () => {
    dispatch(nuevaCotizacion);
  };
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Typography color="textPrimary" className={classes.title}>
        {"¡Lo sentimos!"}
      </Typography>
      <Typography color="textPrimary" className={classes.subtitle}>
        {"Hubo un error o no existen pólizas para tu vehículo."}
      </Typography>
      <div className={classes.imageContainer}>
        <img className={classes.emptyImage} src={emptyImage} alt="" />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonBack}
          onClick={handleBack}
          variant="contained"
        >
          VOLVER
        </Button>
        <Button
          className={classes.button}
          onClick={handleCotizar}
          variant="contained"
          color="primary"
        >
          COTIZAR
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
