import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectProvincia } from "./actions";
import VehiculoProvinciaSelector from "~shared/vehiculoProvinciaSelector/VehiculoProvinciaSelector";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import SlideCustom from "~shared/slideCustom/SlideCustom";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    provinciaSelected: state.datosVehiculoReducer.provinciaSelected
  }));

const ProvinciaStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { provinciaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(selectProvincia({ label: null, value: null }));
  }, [dispatch]);

  const handleChange = event => {
    dispatch(selectProvincia(event.target.value));
  };

  return (
    <>
      <TopBar title={"¿Está radicado en?"} />
      <SlideCustom backNav={provinciaSelected.label}>
        <div className={classes.cotizador}>
          <VehiculoProvinciaSelector
            value={provinciaSelected}
            onChange={handleChange}
          />
        </div>
      </SlideCustom>
    </>
  );
});

export default ProvinciaStep;
