import React, { Fragment, useEffect } from "react";
import useReactRouter from "use-react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "~css/theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Home from "~components/home/Home";
import CotizadorSeguroForm from "~components/cotizadorSeguro/CotizadorSeguroForm";
import SnackbarsProvider from "~shared/snackbarsProvider/SnackbarsProvider";
import useScrollToTop from "~shared/scrollToTop/ScrollToTop";

const PublicViews = () => {
  const { history, location } = useReactRouter();

  useEffect(() => {
    //Redirect to home at the first time
    const isThisUrl = location.pathname === "/";
    if (!isThisUrl) history.replace("/");

    // eslint-disable-next-line
  }, []);

  useScrollToTop();

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/seguros" component={CotizadorSeguroForm} />
    </Switch>
  );
};

const App = () => {
  return (
    <Fragment>
      <CssBaseline />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarsProvider />
          <PublicViews />
        </ThemeProvider>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
