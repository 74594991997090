import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCodigoPostal } from "./actions";
import CodigoPostalSelector from "~shared/codigoPostalSelector/CodigoPostalSelector";
import TopBar from "~shared/TopBar";
import makeStyles from "@material-ui/styles/makeStyles";
import SlideCustom from "~shared/slideCustom/SlideCustom";
import { setInitialStatePolizaDetails } from "../polizaDetailStep/actions";
import { setInitialStatePolizas } from "../polizasStep/polizaSelector/actions";
import { setInitialStateCotizador } from "../actions";

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    minHeight: "100vh"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    codigoPostalSelected: state.datosVehiculoReducer.codigoPostalSelected,
    provinciaSelected: state.datosVehiculoReducer.provinciaSelected
  }));

const CodigoPostalStep = memo(({ cotizar, backAction, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { codigoPostalSelected, provinciaSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(setInitialStateCotizador());
    dispatch(setCodigoPostal({ label: null, value: null }));
  }, [dispatch]);

  const handleChange = event => {
    dispatch(setInitialStatePolizaDetails());
    dispatch(setInitialStatePolizas());
    dispatch(setCodigoPostal(event.target.value));
  };

  return (
    <>
      <TopBar backAction={backAction} title={title} />
      <SlideCustom backNav={codigoPostalSelected.label}>
        <div className={classes.cotizador}>
          <CodigoPostalSelector
            value={codigoPostalSelected}
            provincia={provinciaSelected}
            onChange={handleChange}
            cotizar={cotizar}
          />
        </div>
      </SlideCustom>
    </>
  );
});

export default CodigoPostalStep;
