import { createAction, handleActions } from "redux-actions";
import { getPolizasSeguros } from "./polizasStep/polizaSelector/actions";
import { selectConfirm } from "./ingresaTusDatosStep/actions";
import { setInitialCurrentPoliza } from "./polizaDetailStep/actions";
import { setCodigoPostal } from "./datosVehiculoStep/actions";

const setInitialStateCotizador = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_COTIZADOR",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const unselectPoliza = createAction(
  "UNSELECT_POLIZA",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const backToVehiculoSelector = createAction("BACK_TO_DATOS_VEHICULO_SEGUROS");

const selectPoliza = createAction(
  "SEGUROS_COTIZADOR_POLIZA_SELECT",
  (polizaSelected, seenDetails) => ({ polizaSelected, seenDetails })
);

const postPedidoCotizacionInited = createAction(
  "VEHICULOS_PEDIDO_COTIZACION_INITED"
);
const postPedidoCotizacionCompleted = createAction(
  "VEHICULOS_PEDIDO_COTIZACION_COMPLETED",
  (cotizacionId) => ({ cotizacionId })
);
const postPedidoCotizacionFailed = createAction(
  "VEHICULOS_PEDIDO_COTIZACION_FAILED",
  (error) => ({ error })
);

const postPedidoCotizacion = (modelo, anio, codigoPostal, provinciaId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        postPedidoCotizacionInited({ modelo, anio, codigoPostal, provinciaId })
      );

      const data = {
        Negocio: "MercadoAbierto",
        Periodo: "Anual",
        FormaPago: "DebitoConCBU",
        ModeloVehiculo: modelo,
        AnioVehiculo: anio,
        CodigoPostal: codigoPostal,
        ProvinciaId: provinciaId,
        SocketId: getState().appReducer.socketId,
      };

      const response = await services.api.seguros().postCotizacion(data);

      let cotizacionId = null;

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0 &&
        response.idMulticotizacion
      ) {
        cotizacionId = response.idMulticotizacion;
      } else {
        throw new Error("fail on postCotizacion");
      }

      dispatch(getPolizasSeguros(cotizacionId));

      dispatch(postPedidoCotizacionCompleted(cotizacionId));
    } catch (error) {
      console.error(error);
      dispatch(postPedidoCotizacionFailed(error));
    }
  };
};

const backToDatosVehiculos = () => (dispatch, getState, services) => {
  dispatch(backToVehiculoSelector());
  dispatch(setCodigoPostal({ value: null, label: null }));
  dispatch(selectConfirm(false));
  dispatch(setInitialCurrentPoliza(null));
};

const initialState = {
  cotizacionId: null,
  polizaSelected: false,
};

const cotizadorSeguroReducer = handleActions(
  {
    [setInitialStateCotizador]: (state, action) => {
      return {
        ...initialState,
      };
    },
    [postPedidoCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        cotizacionId: action.payload.cotizacionId,
      };
    },
    [selectPoliza]: (state, action) => {
      return {
        ...state,
        polizaSelected: true,
      };
    },
    [unselectPoliza]: (state, action) => {
      return {
        ...state,
        polizaSelected: false,
      };
    },
  },
  initialState
);

export default cotizadorSeguroReducer;
export {
  setInitialStateCotizador,
  backToDatosVehiculos,
  selectPoliza,
  unselectPoliza,
  postPedidoCotizacion,
};
