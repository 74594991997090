import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import isNullOrEmpty from "~libs/isNullOrEmpty";
import EmailValidator from "email-validator";

const EmailField = ({ required, displayError, onChange, value }) => {
  const [errorText, setErrorText] = useState("Requerido");
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleChange = event => {
    const validation = validateEmail(event.target.value, required);

    setTouched(false);
    setValid(validation.valid);
    setErrorText(validation.error);
    if (onChange)
      onChange({
        target: {
          value: {
            value: event.target.value,
            valid: validation.valid
          }
        }
      });
  };

  const handleBlur = () => {
    const validation = validateEmail(value, required);

    setTouched(true);
    setValid(validation.valid);
    setErrorText(validation.error);

    if (onChange)
      onChange({
        target: {
          value: {
            value: value,
            valid: validation.valid
          }
        }
      });
  };

  const showError = !valid && (displayError || touched);

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      helperText={showError ? errorText : ""}
      error={showError}
      label="Correo electrónico"
      fullWidth
    />
  );
};

export default EmailField;

const validateEmail = (email, isRequired) => {
  if (isNullOrEmpty(email) && isRequired)
    return { valid: false, error: "Requerido" };

  if (!isNullOrEmpty(email) && !EmailValidator.validate(email))
    return {
      valid: false,
      error: "La dirección de correo electrónico no es válida"
    };

  return { valid: true, error: "" };
};
