import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import logoMG from "~images/brand.svg";
import SSN from "~images/SSN.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    paddingBottom: "3rem",
    paddingTop: "3rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#232360",
    minHeight: "50vh"
  },
  container: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    "@media (min-width:1366px)": {
      alignItems: "normal"
    }
  },
  mgInfo: {
    display: "flex",
    paddingTop: "1rem",
    color: theme.colours.white,
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  derechosReservados: {
    fontSize: "0.8rem",
    paddingBottom: "3rem",
    color: theme.colours.white,
    lineHeight: "28px"
  },
  mainImage: {
    width: "11rem"
  },
  SSNImage: {
    width: "12rem"
  },
  infoSection: {
    display: "flex",
    width: "100%",
    color: theme.colours.white,
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    "@media (min-width:1366px)": {
      justifyContent: "baseline",
      textAlign: "left",
      alignItems: "flex-start",
      width: "25%"
    }
  },
  textGroup: {
    paddingBottom: "2rem"
  },
  title: {
    color: theme.colours.white,
    fontWeight: "bold",
    fontSize: "normal",
    lineHeight: "30px"
  },
  subtitle: {
    color: theme.colours.white,
    fontSize: "0.9rem"
  },
  titleSSN: {
    color: theme.colours.white,
    fontWeight: "bold",
    fontSize: "0.7rem",
    textAlign: "left",
    lineHeight: "1.4"
  },
  subtitleSSN: {
    color: theme.colours.white,
    fontSize: "0.7rem",
    textAlign: "center",
    lineHeight: "1.4"
  },
  ssnText: {
    display: "flex",
    paddingBottom: "1rem",
    paddingTop: "0.7rem",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    flexDirection: "row"
  },
  ssn: {
    display: "flex",
    paddingTop: "1rem",
    flexWrap: "wrap-reverse",
    justifyContent: "center",
    alignItems: "baseline",
    flexDirection: "row",
    maxWidth: "450px",
    "@media (min-width:1366px)": {
      maxWidth: "100%",
      justifyContent: "space-between"
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.mgInfo}>
          <div className={classes.infoSection}>
            <img className={classes.mainImage} src={logoMG} alt="" />
            <Typography
              color="textPrimary"
              className={classes.derechosReservados}
            >
              {"2019 - Todos los derechos reservados"}
            </Typography>
          </div>
          <div className={classes.infoSection}>
            <TextGroup
              title={"Oficina"}
              subtitle={"Mitre 907 Piso 7, Rosario, Santa Fe"}
            />
            <TextGroup title={"Teléfono"} subtitle={"+54 9 341 679 5100"} />
          </div>
          <div className={classes.infoSection}>
            <TextGroup title={"Whatsapp"} subtitle={"+54 9 341 519 7094"} />
            <TextGroup
              title={"Denuncia de siniestros"}
              subtitle={"+54 9 341 671 1141"}
            />
          </div>

          <div className={classes.infoSection}>
            <TextGroup
              title={"Asesoría legal"}
              subtitle={"+54 9 341 600 3486"}
            />
            <div className={classes.textGroup}>
              <a
                href={"http://www.mg-group.com.ar/seguros"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography color="textPrimary" className={classes.subtitle}>
                  {"www.mg-group.com.ar/seguros"}
                </Typography>
              </a>
              <Typography color="textPrimary" className={classes.subtitle}>
                {"seguros@mg-group.com.ar"}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.ssn}>
          <div className={classes.ssnText}>
            <Typography color="textPrimary" className={classes.titleSSN}>
              {"Superintendencia de Seguros de la Nación. "}
              &nbsp;
            </Typography>
            <Typography color="textPrimary" className={classes.subtitleSSN}>
              {"Órgano de Control de la Actividad Aseguradora y Reaseguradora."}
              &nbsp;
            </Typography>
            <Typography color="textPrimary" className={classes.subtitleSSN}>
              {"0800-666-8400 l www.ssn.gob.ar."}
              &nbsp;
            </Typography>
            <Typography color="textPrimary" className={classes.subtitleSSN}>
              {"Nro. de Inscripción: 73914"}
              &nbsp;
            </Typography>
          </div>
          <img className={classes.SSNImage} src={SSN} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;

const TextGroup = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.textGroup}>
      <Typography color="textPrimary" className={classes.title}>
        {title}
      </Typography>
      <Typography
        display="initial"
        color="textPrimary"
        className={classes.subtitle}
      >
        {subtitle}
      </Typography>
    </div>
  );
};
