import { createAction, handleActions } from "redux-actions";

const fetchVehiculosModelosInited = createAction("VEHICULOS_MODELOS_INITED");
const fetchVehiculosModelosCompleted = createAction(
  "VEHICULOS_MODELOS_COMPLETED",
  (response) => {
    let modelos = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      modelos = response.items.map((x) => ({
        value: x.codia,
        label: x.version,
      }));
    }
    return { modelos };
  }
);
const fetchVehiculosModelosFailed = createAction(
  "VEHICULOS_MODELOS_FAILED",
  (error) => ({ error })
);

const fetchVehiculosModelos = (marca, anio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchVehiculosModelosInited({ marca, anio }));

      const response = await services.api.vehiculos().getModelos(marca, anio);

      dispatch(fetchVehiculosModelosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchVehiculosModelosFailed(error));
    }
  };
};

const initialState = { isLoading: false, modelos: [] };

const vehiculosModelosReducer = handleActions(
  {
    [fetchVehiculosModelosInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [fetchVehiculosModelosCompleted]: (state, action) => {
      return {
        ...state,
        modelos: [...action.payload.modelos],
        isLoading: false,
      };
    },
    [fetchVehiculosModelosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  initialState
);

export default vehiculosModelosReducer;
export { fetchVehiculosModelos };
