import React, { useState, Fragment, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "../listView/listView";
import Searcher from "../searcher/searcher";
import filterItems from "~libs/filterItems";
import getAnios from "./js/getAnios";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const aniosList = getAnios();

const VehiculoAnioSelector = memo(({ value, onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = value => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <Fragment>
      {!value.label && (
        <div className={classes.searcher}>
          <Searcher
            onClear={() => handleItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar año"}
          />
          <ListView
            selectItem={handleItem}
            list={filter === "" ? aniosList : filterItems(aniosList, filter)}
          />
        </div>
      )}
    </Fragment>
  );
});

export default VehiculoAnioSelector;
