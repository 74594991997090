import { createAction, handleActions } from "redux-actions";

const nuevaCotizacionSeguros = createAction("NUEVA_COTIZACION_SEGUROS");

const fetchVehiculosMarcasInited = createAction("VEHICULOS_MARCAS_INITED");
const fetchVehiculosMarcasCompleted = createAction(
  "VEHICULOS_MARCAS_COMPLETED",
  response => {
    let marcas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      marcas = response.map(x => ({ value: x, label: x }));
    }

    return { marcas };
  }
);
const fetchVehiculosMarcasFailed = createAction(
  "VEHICULOS_MARCAS_FAILED",
  error => ({ error })
);

const fetchVehiculosMarcas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(nuevaCotizacionSeguros());
      dispatch(fetchVehiculosMarcasInited());

      const response = await services.api.vehiculos().getMarcas();

      dispatch(fetchVehiculosMarcasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchVehiculosMarcasFailed(error));
    }
  };
};

const initialState = { isLoading: false, marcas: [] };

const vehiculosMarcasReducer = handleActions(
  {
    [fetchVehiculosMarcasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [fetchVehiculosMarcasCompleted]: (state, action) => {
      return {
        ...state,
        marcas: [...action.payload.marcas],
        isLoading: false
      };
    },
    [fetchVehiculosMarcasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default vehiculosMarcasReducer;
export { fetchVehiculosMarcas };
