import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import brand from "~images/brand.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { Typography, IconButton, Slide } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { nuevaCotizacionTopBar } from "../../home/actions";
import Menu from "./Menu";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#424a90",
    position: "fixed",
    width: "100vw",
    height: 60,
    top: 0,
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    alignItems: "center"
  },
  link: {
    color: theme.colours.white,
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(12)
  },
  linksContainer: {
    display: "none",
    "@media (min-width:768px)": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%"
    }
  },
  brandIcon: {
    objectFit: "contain",
    alignSelf: "center",
    height: "2em",
    marginTop: "10px"
  },
  button: {
    width: "30%",
    height: "28px",
    borderRadius: "23px",
    boxShadow: "2px 2px 10px 0 rgba(57, 93, 73, 0.28)",
    border: `solid 1px ${theme.colours.white}`,
    color: theme.colours.white,
    textTransform: "none",
    "@media (min-width:768px)": {
      marginRight: 20
    }
  },
  buttonEmpty: {
    width: "30%",
    height: "28px",
    borderRadius: "23px",
    marginRight: 20
  },
  textCotizar: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 3,
    letterSpacing: "normal",
    textAlign: "left"
  },
  columnRight: {
    width: 250,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "@media (min-width:768px)": {
      justifyContent: "space-between"
    }
  },
  menuButton: {
    color: theme.colours.white,
    padding: "0px 10px 0px 10px",
    "@media (min-width:768px)": {
      display: "none"
    }
  }
}));

const TopBarHome = ({ showCotizar }) => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const handleScroll = () => {
    setIsVisible(window.pageYOffset >= 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleCotizarButton = () => {
    dispatch(nuevaCotizacionTopBar());
  };
  const [open, setOpen] = useState(false);

  const handleClickMenu = () => {
    setOpen(true);
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Menu
        open={open}
        onClick={handleCotizarButton}
        onClose={() => {
          setOpen(false);
        }}
      />

      <div className={classes.root}>
        <div className={classes.container}>
          <Link to={"/"} style={{ textTransform: "none" }}>
            <img src={brand} className={classes.brandIcon} alt="" />
          </Link>
          <div className={classes.columnRight}>
            <Slide direction="down" in={isVisible || showCotizar}>
              <Button
                className={classes.button}
                onClick={handleCotizarButton}
                to={"/seguros/marca"}
                fullWidth
                component={Link}
              >
                <Typography className={classes.textCotizar}>cotizar</Typography>
              </Button>
            </Slide>
            <div className={classes.linksContainer}>
              <a
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.mg-group.com.ar/"
              >
                Quiénes somos
              </a>

              <HashLink className={classes.link} to="/#footer">
                Contacto
              </HashLink>
            </div>

            <IconButton
              className={classes.menuButton}
              onClick={handleClickMenu}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopBarHome;
