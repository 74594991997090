import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/styles";
import { TC, TP, TR } from "./tipoPoliza";
import LoadingButton from "./LoadingButton";
import Paper from "@material-ui/core/Paper";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  paperTitle: {
    background: theme.colours.white,
    borderRadius: "solid 1px 1px 1px 1px"
  },
  smallFont: {
    fontSize: "normal"
  },
  normalFont: {
    fontSize: "1.2rem"
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: 1.29,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
    fontSize: "1rem"
  },
  disabled: {},
  button: {
    "&$disabled": {
      background: theme.colours.white,
      color: theme.colours.grey5,
      lineHeight: "16px",
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 0,
      width: "100%",
      height: "100%"
    },
    justifyContent: "center",
    display: "flex",
    alignItems: "baseline",
    padding: 0,
    width: "100%",
    background: theme.colours.white,
    color: theme.colours.grey5,
    fontWeight: "bold",
    lineHeight: "16px",
    height: "100%",
    borderRadius: 0
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "strech",
    flexDirection: "row"
  },
  innerTriangle: {
    borderRight: "15px solid #d5d5d5",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },
  innerTriangleSelected: {
    borderRight: "15px solid #44459C",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },

  buttonContainer: {
    display: "flex",
    background: theme.colours.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5"
  },
  buttonContainerSelected: {
    display: "flex",
    background: theme.colours.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    border: "2px solid #44459C"
  },
  loading: { width: "100%", marginTop: "1%" },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    textDecoration: "lowercase",
    justifyContent: "space-around",
    paddingTop: "4px"
  },
  detailsText: {
    fontSize: "12px",
    color: theme.colours.verdeMgBroker,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    textDecoration: "underline",
    textTransform: "lowercase"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    currentPoliza: state.polizaDetailSegurosReducer.currentPoliza
  }));

const CompaniaGroup = ({ isLoading, compania, onPolizaSelected }) => {
  const { currentPoliza } = useReduxSelector();

  const polizaTC = compania.cotizaciones.find(item => {
    return item.tipoPlanComercial === TC ? item : null;
  });
  const polizaTP = compania.cotizaciones.find(item => {
    return item.tipoPlanComercial === TP ? item : null;
  });
  const polizaTR = compania.cotizaciones.find(item => {
    return item.tipoPlanComercial === TR ? item : null;
  });

  const handleClick = poliza => () => {
    if (onPolizaSelected) onPolizaSelected({ ...poliza, ...compania });
  };

  const hasPolizas = polizaTC || polizaTP || polizaTR;

  const classes = useStyles();
  return (
    <Fragment>
      {(isLoading || hasPolizas) && (
        <div style={{ paddingTop: 15 }}>
          <Paper className={classes.paperTitle}>
            <Typography variant={"h6"} className={classes.headerTitle}>
              {!isLoading && compania.compania}
              {isLoading && (
                <div className={classes.loading}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <ContentLoader
                      height={10}
                      width={93}
                      speed={2}
                      backgroundColor="#f7f8ff"
                      foregroundColor="#6464BB"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="92" height="10" />
                    </ContentLoader>
                  </div>
                </div>
              )}
            </Typography>
            <Fragment>
              {isLoading && (
                <div className={classes.loadingContainer}>
                  <LoadingButton selected={true} />
                  <LoadingButton />
                  <LoadingButton />
                </div>
              )}
              {!isLoading && hasPolizas && (
                <div className={classes.loadingContainer}>
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTC}
                    selected={
                      currentPoliza &&
                      polizaTC &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTC.cotizacionPlanId
                    }
                  />
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTP}
                    selected={
                      currentPoliza &&
                      polizaTP &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTP.cotizacionPlanId
                    }
                  />
                  <PolizaButton
                    onClick={handleClick}
                    poliza={polizaTR}
                    selected={
                      currentPoliza &&
                      polizaTR &&
                      currentPoliza.cotizacionPlanId ===
                        polizaTR.cotizacionPlanId
                    }
                  />
                </div>
              )}
            </Fragment>
          </Paper>
        </div>
      )}
    </Fragment>
  );
};

export default CompaniaGroup;

const PolizaButton = ({ onClick, poliza, selected }) => {
  const classes = useStyles();

  let font = "normalFont";
  if (poliza && poliza.importe >= 10000) font = "smallFont";
  return (
    <div
      className={
        !selected ? classes.buttonContainer : classes.buttonContainerSelected
      }
    >
      <div className={classes.button}>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.disabled
          }}
          onClick={onClick(poliza)}
          disabled={!poliza}
        >
          <Fragment>
            {poliza ? (
              <div className={classes.buttonContent}>
                <NumberFormat
                  className={classes[font]}
                  value={poliza.importe}
                  displayType={"text"}
                  decimalScale={0}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$ "}
                  suffix={" "}
                />
                <Typography className={classes.detailsText} variant="subtitle2">
                  ver detalle
                </Typography>
              </div>
            ) : (
              <Fragment>&mdash;</Fragment>
            )}
          </Fragment>
        </Button>
      </div>
      <div
        className={
          !selected ? classes.innerTriangle : classes.innerTriangleSelected
        }
      />
    </div>
  );
};
