import { buildRequest, errorUnlessOk } from "./utils";
import environment from "~libs/environment";

const localhost = false;

const seguros = () => {
  return {
    postCotizacion: data => {
      let request = buildRequest("POST", data);

      let url = `${environment.segurosUrl}/multicotizaciones`;
      if (localhost) url = `${environment.localhostUrl}/multicotizaciones`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then(response => {
          return response.json();
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    putCotizacion: (idMulticotizacion, data) => {
      let request = buildRequest("PUT", data);

      let url = `${environment.segurosUrl}/multicotizaciones/${idMulticotizacion}`;
      if (localhost)
        url = `${environment.localhostUrl}/multicotizaciones/${idMulticotizacion}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    getPolizas: idMulticotizacion => {
      let request = buildRequest("GET");

      let url = `${environment.segurosUrl}/multicotizaciones/${idMulticotizacion}`;
      if (localhost)
        url = `${environment.localhostUrl}/multicotizaciones/${idMulticotizacion}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then(response => {
          return response.json();
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    postSolicitudesPoliza: data => {
      let request = buildRequest("POST", data);

      let url = `${environment.segurosUrl}/solicitudespoliza`;
      if (localhost) url = `${environment.localhostUrl}/solicitudespoliza`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    }
  };
};

export { seguros };
