import { createAction, handleActions } from "redux-actions";
import { unselectPoliza } from "../actions";

const backToPolizas = createAction("BACK_TO_ELIGE_TU_POLIZA");

const setInitialStatePolizaDetails = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_STATE_POLIZA_DETAILS",
  () => {},
  () => ({ doNotSendToAnalytics: true })
);

const setInitialCurrentPoliza = createAction(
  "NUEVA_COTIZACION_SEGUROS_SET_INITIAL_DEFAULT_POLIZA",
  defaultPoliza => ({ defaultPoliza }),
  () => ({ doNotSendToAnalytics: true })
);

const setCurrentPoliza = createAction(
  "POLIZA_VER_POLIZA_DETALLES",
  currentPoliza => ({ currentPoliza })
);

const postSolicitudesPolizaInited = createAction(
  "POLIZA_POST_SOLICITUDES_POLIZA_INITED"
);
const postSolicitudesPolizaCompleted = createAction(
  "POLIZA_POST_SOLICITUDES_POLIZA_COMPLETED",
  (response, polizas) => ({ response, polizas })
);
const postSolicitudesPolizaFailed = createAction(
  "POLIZA_POST_SOLICITUDES_POLIZA_FAILED",
  error => ({ error })
);

const postSolicitudesPoliza = (idMulticotizacion, idPlanSeleccionado) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        postSolicitudesPolizaInited({ idMulticotizacion, idPlanSeleccionado })
      );

      const data = { idMulticotizacion, idPlanSeleccionado };

      const response = await services.api.seguros().postSolicitudesPoliza(data);

      dispatch(postSolicitudesPolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postSolicitudesPolizaFailed(error));
    }
  };
};

const backToEligeTuPoliza = () => (dispatch, getState, services) => {
  dispatch(backToPolizas());
  dispatch(unselectPoliza());
};

const initialState = {
  currentPoliza: null,
  showDetail: false
};
const polizaDetailSegurosReducer = handleActions(
  {
    [setInitialStatePolizaDetails]: (state, action) => {
      return {
        ...state,
        showDetail: false,
        currentPoliza: null
      };
    },
    [backToPolizas]: (state, action) => {
      return {
        ...state,
        showDetail: false
      };
    },
    [setInitialCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.defaultPoliza
      };
    },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.currentPoliza,
        showDetail: true
      };
    }
  },

  initialState
);

export default polizaDetailSegurosReducer;
export {
  setCurrentPoliza,
  setInitialCurrentPoliza,
  postSolicitudesPoliza,
  backToEligeTuPoliza,
  setInitialStatePolizaDetails
};
