import { buildRequest, errorUnlessOk } from "./utils";
import environment from "~libs/environment";

const vehiculos = () => {
  return {
    getMarcas: () => {
      let request = buildRequest("GET", null, {
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      return fetch(`${environment.vehiclesUrl}/marcas`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getModelos: (marca, anio) => {
      let request = buildRequest("GET", null, {
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      return fetch(
        `${environment.vehiclesUrl}/marcas/${encodeURI(
          marca
        )}/anios/${encodeURI(anio)}/versiones`,
        request
      )
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

const provincias = () => {
  return {
    getCodigosPostales: (provinciaId, localidad) => {
      let request = buildRequest("GET");

      let finalUrl = `${environment.catalogosUrl}/provincias/${encodeURI(
        provinciaId
      )}/codigospostales`;

      finalUrl = localidad
        ? `${finalUrl}?localidad=${encodeURI(localidad)}`
        : finalUrl;

      return fetch(finalUrl, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { vehiculos, provincias };
